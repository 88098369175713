import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'; // Import useNavigate for navigation
import DOMPurify from 'dompurify';

const Products = (props) => {
    const location = useLocation(); // Access location object
    const products = location.state?.products || []; // Get products from state or default to empty array
    console.log("productsproducts", products);

    const navigate = useNavigate(); // Initialize useNavigate
    // Function to handle navigation to product details
    const handleView = (product) => {
        navigate(`/product-details?id=${product?.product_id}`, { state: { product: product } });
    };

    // Sort products alphabetically by name
    const sortedProducts = [...products].sort((a, b) => {
        const nameA = DOMPurify.sanitize(a.name).replace(/<[^>]*>/g, '');
        const nameB = DOMPurify.sanitize(b.name).replace(/<[^>]*>/g, '');
        return nameA.localeCompare(nameB); // Comparing the text content
    });
    return (
        <div style={{ paddingTop: '80px' }}>
            <h1 style={{ textAlign: 'center' }}>Welcome to HEW Products</h1>
            <p style={{ textAlign: 'center' }}>Here's a list of equipment that we manufacture at HEW.</p>
            <div style={{
                backgroundColor: 'rgb(225, 237, 252)',
                padding: '10px',
                minHeight: 'calc(100vh - 100px)', // Adjust for the header and navbar height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: products.length > 0 ? 'flex-start' : 'center',
                alignItems: 'center',
            }}>
                {sortedProducts?.length > 0 ? (
                    sortedProducts?.map((product, index) => (
                        <div
                            key={index}
                            className="product-container"
                            style={{
                                display: 'flex',
                                flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                                marginBottom: '60px',
                                height: '350px',
                                width: '100%',
                            }}
                        >
                            <div className="product-image" style={{ flex: 1, marginRight: '10px', marginLeft: '10px', background: '#fff', overflow: 'hidden' }}>
                                <img
                                    src={product.image.replace('.s3.amazonaws.com', '')}
                                    alt={product.name}
                                    className="zoom-image"
                                    style={{ width: '100%', height: '100%', objectFit: 'contain' }} // Reduced width
                                />
                            </div>
                            <div className="product-details" style={{ flex: 1, padding: '20px', display: 'flex', flexDirection: 'column' }}>
                                <div style={{}}>
                                    <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.name) }} />
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.desc) }} />
                                </div>
                                <button
                                    onClick={() => handleView(product)}
                                    style={{
                                        alignSelf: 'flex-start',
                                        marginTop: '20px',
                                        padding: '10px 15px',
                                        backgroundColor: '#fff',
                                        color: 'black',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s, transform 0.3s', // Transition for hover effects
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#f0f0f0';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#fff';
                                    }}
                                >
                                    <i className="fa fa-eye" style={{ marginRight: '8px' }}></i> {/* Eye icon */}
                                    View this product
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        No products listed yet.
                    </div>
                )}
            </div>

            <style jsx>{`
                .product-container {
                    margin-bottom: 20px;
                }
                .product-image {
                    transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
                }
                .product-image:hover {
                    transform: scale(1.05);
                }
                .product-image img {
                    max-width: 100%; /* Responsive image */
                    height: auto;
                }
        
                // .product-image img:hover {
                //     transform: scale(1.05);
                // }
        
                .product-details {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                }
            `}</style>
            <div className="social-media-container" >
                <span className="social-media-text">Social Media:</span>
                <div className="social-media-icons">
                    <a href="https://www.facebook.com/rajukumar.singh.3914" target="_blank"><img src="../img/icons/facebook.png" alt="" /></a>
                    <a href="https://www.youtube.com/@hariomenggworksofficial" target="_blank"><img src="../img/icons/youtube.png" alt="" /></a>
                    <a href="https://x.com/hariomenggwork1" target="_blank"><img src="../img/icons/twitter.png" alt="" /></a>
                    <a href="https://www.linkedin.com/in/hariom-engineering-works-74412464" target="_blank"><img src="../img/icons/linkedin.png" alt="" /></a>
                    <a href="https://www.instagram.com/hariomenggworks2011" target="_blank"><img src="../img/icons/instagram.png" alt="" /></a>
                </div>
            </div>
        </div>
    );
};

export default Products;
