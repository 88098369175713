import React from "react";

export const Services = (props) => {
  return (
    <>
    <div id="services">
      {/* <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  <i className={d.icon}></i>
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div> */}
      <div className="section-title text-center">
        <h2>Services</h2>
      </div>
      <div className="service-container">
        <div className="service-frame">
          <img src="../img/services.png" alt="" />
        </div>
        <div className="service-info-content">
          <span className="service-info-title">MAINTENANCE & REPAIR</span>
          <ul className="service-ul">
            <li>We provide on-site maintenance and repair services.</li>
            <li>Our team is capable of repairing products of any make with complete technical expertise.</li>
            <li>If on-site repair is not feasible due to machine mobility issues or other constraints, we efficiently carry out repairs at our factory premises.</li>
          </ul>
        </div>
      </div>
      
    </div>
     <div className="social-media-container" style={{marginBottom:'7px'}}>
     <span className="social-media-text">Social Media:</span>
     <div className="social-media-icons">
       <a href="https://www.facebook.com/rajukumar.singh.3914" target="_blank"><img src="../img/icons/facebook.png" alt="" /></a>
       <a href="https://www.youtube.com/@hariomenggworksofficial" target="_blank"><img src="../img/icons/youtube.png" alt="" /></a>
       <a href="https://x.com/hariomenggwork1" target="_blank"><img src="../img/icons/twitter.png" alt="" /></a>
       <a href="https://www.linkedin.com/in/hariom-engineering-works-74412464" target="_blank"><img src="../img/icons/linkedin.png" alt="" /></a>
       <a href="https://www.instagram.com/hariomenggworks2011" target="_blank"><img src="../img/icons/instagram.png" alt="" /></a>
     </div>
   </div>
   </>
  );
};
