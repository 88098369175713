import React, { useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const Enquiry = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {



    // Check for mandatory fields
    if (!firstName || !lastName || !phone || !email || !message) {
      toast.error("All fields are mandatory.");
      return;
    }

    // Validate email before sending request
    if (!validateEmail(email)) {
      toast.error("Enter a valid email address.");
      return;
    }

    const formData = {
      firstName,
      lastName,
      phone,
      email,
      message,
    };

    const bodyJSON = {
      enquiryData: formData
    }

    // console.log("fdfdf", bodyJSON);

    try {
      const response = await fetch("https://lpodw9qjh7.execute-api.eu-north-1.amazonaws.com/production/products", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyJSON),
      });

      if (response.ok) {
        Swal.fire({
          title: "Success!",
          text: "You have successfully submitted enquiry form.",
          icon: "success",
          confirmButtonText: "Great!",
        }).then((result) => {
          if (result.isConfirmed) {
            setFirstName("");
            setLastName("");
            setPhone("");
            setEmail("");
            setMessage("");
          }
        });
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "There was an issue while submitting enquiry.");
      }

    } catch (error) {
      toast.error("There was an error sending your enquiry. Please try again later.");
    }
  };

  return (
    <>
      <div id="portfolio">
        <div className="section-title text-center">
          <h2>Enquiry</h2>
        </div>
        <div className="enquiry-form-container">
          <img src="../img/enquiry-logo.jpg" alt="" />
          <div className="enquiry-form-content">
            <div className="enquiry-form-wrapper">
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder=""
                autoComplete="off"
              />
              <label htmlFor="firstName">First Name</label>
              <span className="underline"></span>
            </div>
            <div className="enquiry-form-wrapper">
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder=""
                autoComplete="off"
              />
              <label htmlFor="lastName">Last Name</label>
              <span className="underline"></span>
            </div>
            <div className="enquiry-form-wrapper">
              <input
                type="text" // Change type to text
                value={phone}
                maxLength={10} // Set maxLength to 10
                onChange={(e) => {
                  // Allow only numeric input
                  const value = e.target.value;
                  if (/^\d*$/.test(value) && value.length <= 10) {
                    setPhone(value);
                  }
                }}
                placeholder=""
                autoComplete="off"
              />
              <label htmlFor="phone">Phone Number</label>
              <span className="underline"></span>
            </div>
            <div className="enquiry-form-wrapper">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=""
                autoComplete="off"
              />
              <label htmlFor="email">Your Email</label>
              <span className="underline"></span>
            </div>
            <div className="enquiry-form-wrapper full-streach">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder=""
                autoComplete="off"
              ></textarea>
              <label htmlFor="message">Leave Your Message</label>
              <span className="underline"></span>
            </div>
            <button type="button" className="full-streach" onClick={handleSubmit}>
              Send Message
            </button>
          </div>
        </div>
      </div>
      <div className="social-media-container">
        <span className="social-media-text">Social Media:</span>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/rajukumar.singh.3914" target="_blank"><img src="../img/icons/facebook.png" alt="" /></a>
          <a href="https://www.youtube.com/@hariomenggworksofficial" target="_blank"><img src="../img/icons/youtube.png" alt="" /></a>
          <a href="https://x.com/hariomenggwork1" target="_blank"><img src="../img/icons/twitter.png" alt="" /></a>
          <a href="https://www.linkedin.com/in/hariom-engineering-works-74412464" target="_blank"><img src="../img/icons/linkedin.png" alt="" /></a>
          <a href="https://www.instagram.com/hariomenggworks2011" target="_blank"><img src="../img/icons/instagram.png" alt="" /></a>
        </div>
      </div>
      <div className="text-center">
        <img src="../img/make-in-india.png" alt="" />
      </div>
    </>
  );
};

