import { Image } from "./image";
import React from "react";

export const Client = (props) => {
  const clients = [
    {img:'client-1',text:'PowerGrid'},
    {img:'client-2',text:'Tata'},
    {img:'client-3',text:'Sterlite'},
    {img:'client-4',text:'Unitech'},
    {img:'client-5',text:'R.S Infraprojects Pvt. Ltd.'},
    {img:'client-6',text:'Larsen & Toubro'},
    {img:'client-7',text:'KEC INTERNATINAL LIMITED'},
    {img:'client-8',text:'Skipper'},
    {img:'client-9',text:'KPT'},
    {img:'client-10',text:'Bajaj'},
    {img:'client-11',text:'EMC'},
    {img:'client-12',text:'Kalpa-Taru'},
    {img:'client-13',text:'Shyama Power'},
    {img:'client-14',text:'KPTCL'},
  ]
  return (
    <div id="clients" className="text-center">
      <div className="">
        <div className="section-title">
          <h2>Clients</h2>
          <p> We are committed to providing the highest quality product and services for all Transmission line Tools and stringing equipment for overhead power lines. </p>
        </div>
        <div className="custom-text-img-container">
          {clients ? clients.map((d, i) => (
            <div className="custom-text-img">
              <span>{d.text}</span>
              <img src={`../img/client/${d.img}.jpeg`} alt={d.text} />
          </div>
          )):''}
        </div>
        <div className="custom-client-img-container">
          <div className="custom-img-wrapper">
            {clients ? clients.map((d, i) => (
                  <div key={`${i}`} className="customer-img" >
                    <img src={`../img/client/${d.img}.jpeg`} alt={d.text} />
                    {/* <Image title={d.title} largeImage={d.largeImage} smallImage={d.smallImage} /> */}
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      <div className="social-media-container">
      <span className="social-media-text">Social Media:</span>
      <div className="social-media-icons">
        <a href="https://www.facebook.com/rajukumar.singh.3914" target="_blank"><img src="../img/icons/facebook.png" alt="" /></a>
        <a href="https://www.youtube.com/@hariomenggworksofficial" target="_blank"><img src="../img/icons/youtube.png" alt="" /></a>
        <a href="https://x.com/hariomenggwork1" target="_blank"><img src="../img/icons/twitter.png" alt="" /></a>
        <a href="https://www.linkedin.com/in/hariom-engineering-works-74412464" target="_blank"><img src="../img/icons/linkedin.png" alt="" /></a>
        <a href="https://www.instagram.com/hariomenggworks2011" target="_blank"><img src="../img/icons/instagram.png" alt="" /></a>
      </div>
    </div>
    </div>
  );
};

