import React from "react";

export const Testing = (props) => {
  return (
    <>
      <div id="portfolio">
        <div className="container text-center">
          <div className="section-title">
            <h2>Testing</h2>
            <p> We are committed to providing the highest quality product and services for all Transmission line Tools and stringing equipment for overhead power lines. </p>
          </div>
          <div className="custom-testing-img-container">
            <div className="custom-testing-card">
              <img src="../img/testing/Hardness-testing-machine.jpeg" alt="Hardness Testing Machine" />
              <p>Hardness Testing Machine</p>
            </div>
            <div className="custom-testing-card">
              <img src="../img/testing/Load-test-machine.jpeg" alt="Load Test Machine" />
              <p>Load Test Machine</p>
            </div>
            <div className="custom-testing-card">
              <img src="../img/testing/Load-testing-machine.jpeg" alt="Load Testing Machine" />
              <p>Load Testing Machine</p>
            </div>
            <div className="custom-testing-card">
              <img src="../img/testing/Scanning-machine.jpeg" alt="Scanning Machine" />
              <p>Scanning Machine</p>
            </div>
          </div>
        </div>

      </div>
      <div className="social-media-container">
        <span className="social-media-text">Social Media:</span>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/rajukumar.singh.3914" target="_blank"><img src="../img/icons/facebook.png" alt="" /></a>
          <a href="https://www.youtube.com/@hariomenggworksofficial" target="_blank"><img src="../img/icons/youtube.png" alt="" /></a>
          <a href="https://x.com/hariomenggwork1" target="_blank"><img src="../img/icons/twitter.png" alt="" /></a>
          <a href="https://www.linkedin.com/in/hariom-engineering-works-74412464" target="_blank"><img src="../img/icons/linkedin.png" alt="" /></a>
          <a href="https://www.instagram.com/hariomenggworks2011" target="_blank"><img src="../img/icons/instagram.png" alt="" /></a>
        </div>
      </div>
    </>
  );
};

