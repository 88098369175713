import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import { Home } from "./Home";
export const Navigation = (props) => {

  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [uniqueProductType, setUniqueProductType] = useState([]);



  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`https://lpodw9qjh7.execute-api.eu-north-1.amazonaws.com/production/products`);
        const data = await response.json();
        // Set the products state
        setProducts(data);
        // Extract unique product types
        // const unique = Array.from(new Set(data.map(product => product.type)));
        // const productTypesWithAll = ['All', ...unique];
        // console.log("productTypesWithAllproductTypesWithAll",productTypesWithAll);

        const typesArray = ["All", "OPGW Tools", "Erection Tools", "Stringing Tools", "Railway Construction"]

        setUniqueProductType(typesArray);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const handleScrollToSection = (sectionId) => {
    navigate("/"); // Navigate to the home route
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Delay to ensure routing is complete
  };

  const fetchCatalogue = async () => {

    try {
      const response = await fetch('https://lpodw9qjh7.execute-api.eu-north-1.amazonaws.com/production/products?catalogue=true');
      const data = await response.json();

      if (data.catalogueUrl) {
        const updatedCatalogueURL = data.catalogueUrl.replace('.s3.amazonaws.com', '') ?? '';
        if (updatedCatalogueURL) {
          window.open(updatedCatalogueURL, '_blank');

        }
      } else {
        toast.error('Catalogue file not uploaded yet.');
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="">{/* container */}
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="../img/logo.jpeg" alt="" style={
              {
                width: '100px',
                marginTop: '-20px'
              }} />
            <span style={{ color: '#f70200', fontSize: "30px", marginBottom: '6px' }}>HariOm Engineering Works</span>
            {/* <br />
            <span style={{ fontSize: '12.5px', fontStyle: 'italic', fontFamily: 'Roboto, sans-serif', textTransform: 'none' }}>
              An ISO 9001:2008 Certified Co.
            </span> */}
          </a>
        </div>

        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            {/* <li>
              <a  onClick={() => handleScrollToSection('features')} className="page-scroll">Features</a>
            </li> */}
            <li>
              <NavLink to="/" className={({ isActive }) => (isActive ? "page-scroll active" : "page-scroll")}>Home</NavLink>
              {/* <a  className="page-scroll">Home</a> */}
            </li>
            <li>
              <NavLink to="/about" className={({ isActive }) => (isActive ? "page-scroll active" : "page-scroll")}>About</NavLink>
              {/* <a onClick={() => handleScrollToSection('about')} className="page-scroll">About</a> */}
            </li>
            <li>
              <NavLink to="/testing" className={({ isActive }) => (isActive ? "page-scroll active" : "page-scroll")}>Testing</NavLink>
            </li>
            <li>
              <NavLink to="/client" className={({ isActive }) => (isActive ? "page-scroll active" : "page-scroll")}>Clients</NavLink>
            </li>
            {/* 
            <li>
              <a  onClick={() => handleScrollToSection('services')} className="page-scroll">Services</a>
            </li> */}
            {/* <li>
              <a onClick={() => handleScrollToSection('portfolio')} className="page-scroll">Gallery</a>
            </li> */}
            {/* 
            <li>
              <a  onClick={() => handleScrollToSection('testimonials')} className="page-scroll">Testimonials</a>
            </li>
            <li>
              <a  onClick={() => handleScrollToSection('team')} className="page-scroll">Team</a>
            </li> */}

            {/* <li>
              <a onClick={() => handleScrollToSection('contact')} className="page-scroll">Contact Us</a>
            </li> */}
            {/* <li>
              <a onClick={() => navigate('/products')} className="page-scroll">Products</a>
            </li> */}

            <li className="dropdown">
              <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#" > Products <span className="caret"></span> </a>
              <ul className="dropdown-menu">
                {uniqueProductType?.map((category, index) => (
                  <li key={index}>
                    <a
                      onClick={() => {
                        if (category === 'All') {

                          // Navigate to the products page without filtering
                          navigate('/products', { state: { products: products } });
                        } else {
                          // Filter products by the selected category and navigate
                          const filteredProducts = products.filter(product => product.type === category);
                          navigate('/products', { state: { products: filteredProducts } });
                        }
                      }}
                      className="dropdown-item"
                    >
                      {category}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <a target="_self" href="https://admin.stringingtools.in" className="page-scroll">Admin Panel</a>
            </li>
            <li className="dropdown">
              <a className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" href="#" > More <span className="caret"></span> </a>
              <ul className="dropdown-menu">
                <li>
                  <a onClick={fetchCatalogue} className="dropdown-item">Catalogue</a>
                </li>
                <li>
                  <Link to="/enquiry" className={({ isActive }) => (isActive ? "page-scroll active" : "page-scroll")}>Enquiry</Link>
                </li>
                <li>
                  <a onClick={() => navigate('/certifications')} className="dropdown-item">Certifications</a>
                </li>
                <li>
                  <Link to="/services" className={({ isActive }) => (isActive ? "page-scroll active" : "page-scroll")}>Services</Link>
                </li>
                {/* <li>
                  <a onClick={() => navigate('/licenses')} className="dropdown-item">Licenses</a>
                </li> */}
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <style jsx>{`
        .dropdown-menu {
          background-color: rgba(255, 255, 255, 0.9); /* Light background for dropdown */
          border-radius: 4px; /* Rounded corners */
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
          padding: 10px 0; /* Padding around the dropdown */
        }

        .dropdown-item {
          padding: 10px 20px; /* Add padding to each dropdown item */
          color: #333; /* Text color for dropdown items */
          text-decoration: none; /* Remove underline */
          display: block; /* Ensure the entire area is clickable */
        }

        .dropdown-item:hover {
          background-color: rgba(240, 240, 240, 1); /* Light gray background on hover */
          cursor: pointer; /* Change cursor on hover */
          color: inherit; /* Keep original text color on hover */
        }
      `}</style>
    </nav>
  );
};
