import React from 'react';
import { useLocation } from 'react-router-dom'; // For getting query parameters
import DOMPurify from 'dompurify'; 

const ProductDetails = () => {
    
    const location = useLocation();
    const product = location.state?.product || null;


    if (!product) return <div>Loading...</div>; // Show loading state while fetching

    return (
        <div style={{ marginTop:'100px',padding:'20px',position:'relative'}}>
            <span className='product-left-arrow' onClick={()=>goback()}>
                <i className="fa fa-solid fa-arrow-left" ></i>
                Back
            </span>
             <h1 
                style={{ textAlign: 'center' }} 
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.name) }} // Set product name dangerously
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <img
                    src={product.image.replace('.s3.amazonaws.com', '')} 
                    alt={product.name}
                    style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
                />
            </div>
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.desc) }} />

            <div className="social-media-container">
        <span className="social-media-text">Social Media:</span>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/rajukumar.singh.3914" target="_blank"><img src="../img/icons/facebook.png" alt="" /></a>
          <a href="https://www.youtube.com/@hariomenggworksofficial" target="_blank"><img src="../img/icons/youtube.png" alt="" /></a>
          <a href="https://x.com/hariomenggwork1" target="_blank"><img src="../img/icons/twitter.png" alt="" /></a>
          <a href="https://www.linkedin.com/in/hariom-engineering-works-74412464" target="_blank"><img src="../img/icons/linkedin.png" alt="" /></a>
          <a href="https://www.instagram.com/hariomenggworks2011" target="_blank"><img src="../img/icons/instagram.png" alt="" /></a>
        </div>
      </div>
        </div>
    );
};
function goback(){
    window.navigation.back();
}
export default ProductDetails;
