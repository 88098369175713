import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export const SliderCarousal = props => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true
  };
  return (
    <div className="slick-bg">
      {/* <h1>React Slick Carousel Example</h1> */}
      <Slider {...settings} className="custom-carousal">
        {
          props.images  ? props.images.map((img,i) => (
            <div key={i}>
              <img src={`../img/carousel/${img}`} alt="slide 3" />
            </div>
          ))
          : ''
        }
        {/* <div>
          <img src="https://freshcart-next-js.vercel.app/images/slider/slide-1.jpg" alt="slide 1" />
        </div>
        <div>
          <img src="https://freshcart-next-js.vercel.app/images/slider/slide-1.jpg" alt="slide 2" />
        </div>
        <div>
          <img src="https://freshcart-next-js.vercel.app/images/slider/slide-1.jpg" alt="slide 3" />
        </div> */}
      </Slider>
    </div>
  );
};
