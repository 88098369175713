import React from "react";
import { SliderCarousal } from "../common/features/Slider-Carousel";
export const About = (props) => {
  const images = [
    'istockphoto-501773545-612x612.jpg',
    '240_F_360990692_CzXMokvewMu9I3FrOIcIzihIwGVXw23y (1).jpg',
    'pexels-pok-rie-409020-1-scaled-e1698996053877.jpg',
    'shutterstock_140921290.jpg',
    'Transmission Lines.jpg'
  ]
  return <>
    <SliderCarousal images={images} />
    <div id="about">
      {/* <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.webp" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    
    <div className="info-section-container">
      <p className="info-section-heading">ABOUT US</p>
      <p className="info-section-title">India's Largest Transmission Line Equipment’s Manufacturer</p>
      <div className="info-section-texts">
        <p>At <strong>Hariom Engineering Works</strong>, quality is our top priority. As a leading manufacturer of transmission line equipment, our primary objective is to deliver flawless products. To ensure superior performance, we use only high-grade raw materials throughout our production process. Every product undergoes rigorous testing at our well-equipped testing unit before final dispatch.</p>
        <p>We specialize in manufacturing and supplying high-quality transmission line products using advanced tools and machinery. Our modern production techniques allow us to craft products with precise shapes and sizes, ensuring optimal performance, durability, and minimal maintenance. These attributes have made our products highly sought after across the nation.</p>
        <p>To maintain exceptional quality, we conduct regular hydraulic power tests at every stage of production. With the support of our extensive distribution network, we ensure timely delivery of products to our clients.</p>
      </div>
    </div>

    <div className="social-media-container">
      <span className="social-media-text">Social Media:</span>
      <div className="social-media-icons">
        <a href="https://www.facebook.com/rajukumar.singh.3914" target="_blank"><img src="../img/icons/facebook.png" alt="" /></a>
        <a href="https://www.youtube.com/@hariomenggworksofficial" target="_blank"><img src="../img/icons/youtube.png" alt="" /></a>
        <a href="https://x.com/hariomenggwork1" target="_blank"><img src="../img/icons/twitter.png" alt="" /></a>
        <a href="https://www.linkedin.com/in/hariom-engineering-works-74412464" target="_blank"><img src="../img/icons/linkedin.png" alt="" /></a>
        <a href="https://www.instagram.com/hariomenggworks2011" target="_blank"><img src="../img/icons/instagram.png" alt="" /></a>
      </div>
    </div>
    <div className="text-center">
      <img src="../img/make-in-india.png" alt="" />
    </div>
    </div>
  </>
};
