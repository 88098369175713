import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css"; // Ensure this file has basic styles
import Certifications from "./components/certifications";
import Products from "./components/products";
import Licenses from "./components/licenses";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductDetails from "./components/productDetails";
import { Home } from "./components/Home";
import { Client } from "./components/Client";
import { Testing } from "./components/Testing";
import { Enquiry } from "./components/Enquiry";
import { Services } from "./components/services";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setLandingPageData(JsonData);

    // Check if the user has viewed the modal before
    const hasViewedModal = sessionStorage.getItem("hasViewedModal");
     if (!hasViewedModal) {
    setIsModalOpen(true);
    sessionStorage.setItem("hasViewedModal", "true"); // Set the flag
     }
  }, []);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return re.test(String(email).toLowerCase());
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEmail('');
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
  
    // Validate email before sending request
    if (!validateEmail(email)) {
      toast.error("Enter a valid email address.");
      return;
    }
  
    try {

      const response = await fetch('https://lpodw9qjh7.execute-api.eu-north-1.amazonaws.com/production/products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({subscriberEmail: email }), // Send the email in the request body
      });
  
      if (response.ok) {
        // Success - Show a success message
        Swal.fire({
          title: "Subscribed!",
          text: "You have successfully subscribed to the newsletter.",
          icon: "success",
          confirmButtonText: "Great!",
        }).then((result) => {
          if (result.isConfirmed) {
            closeModal();
          }
        });
      } else {
        // Handle errors from the server
        const errorData = await response.json();
        toast.error(errorData.message || "There was an issue with your subscription.");
      }
    } catch (error) {
      // Handle network errors
      toast.error("There was an error sending your subscription. Please try again later.");
    }
  };

  return (
    <Router>
      <div style={{paddingTop:'50px'}}>
        <Navigation />
        <Routes>
          {/* <Route path="/" element={
            <> */}
              {/* <Header data={landingPageData.Header} /> */}
              {/* <About data={landingPageData.About} /> */}
              {/* <Gallery data={landingPageData.Gallery} />
              <Contact data={landingPageData.Contact} /> */}
              {/* <Features data={landingPageData.Features} /> */}

              
            {/* </>
          } /> */}
          <Route path="/" element={<Home data={landingPageData.Header} />} />
          <Route path="/about" element={<About data={landingPageData.About}/>} />
          <Route path="/client" element={<Client data={landingPageData.Gallery  }/>} />
          <Route path="/testing" element={<Testing/>} />
          <Route path="/certifications" element={<Certifications />} />
          <Route path="/licenses" element={<Licenses />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product-details" element={<ProductDetails />} />
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/services" element={<Services />} />
        </Routes>

        {/* Modal Implementation */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content" >
              <button className="close-modal" onClick={closeModal}>&times;</button>
              <div className="modal-header" style={{ marginTop: '30px', borderBottom: 'none' }}>
                <i className="fa fa-envelope" style={{ fontSize: '40px', color: '#fff' }}></i>
              </div>
              <h2 style={{ color: '#fff', fontSize: '24px' }}>Don't Miss Out!</h2>
              <p style={{color:'#fff'}}>Subscribe for the latest Newsletter</p>
              <form onSubmit={handleSubscribe}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                // required
                />
                <div style={{ marginTop: '10px' }}>
                  <button type="submit" className="btn btn-custom btn-lg">Subscribe</button>
                </div>
              </form>
            </div>
          </div>
        )}
        {/* Toast Container for notifications */}
        <ToastContainer />
      </div>

      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          background: linear-gradient(135deg, rgba(76, 191, 134, 1), rgba(0, 153, 204, 1));
        // background: linear-gradient(135deg, rgba(100, 180, 230, 1), rgba(30, 120, 190, 1));
        

          padding: 20px 35px;
          border-radius: 8px;
          text-align: center;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          width: 400px;
          position: relative; /* Make the modal content relative for absolute positioning of the close button */
          
        }

        .close-modal {
          position: absolute;
          top: 10px;
          right: 10px;
          background: transparent;
          border: none;
          color: #fff;
          font-size: 24px;
          cursor: pointer;
        }

        .modal-header {
          font-size: 40px; /* Adjust icon size */
          color: #fff;
          margin-bottom: 10px; /* Space below the icon */
        }

        .modal-header i {
          color: #fff; /* Icon color */
        }

        input[type="email"] {
          padding: 10px;
          margin: 10px 0;
          border: none;
          border-radius: 4px;
          width: 100%;
          outline: none;
        }

        button {
          padding: 10px 20px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          background-color: #007bff;
          color: white;
        }
      `}</style>

    </Router>
  );
};

export default App;
