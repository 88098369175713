import React from "react";
export const CopyRight = props => {
  return <>
    <div className="custom-contact-us-container">
      <div className="custom-contact-us-content">
        <span className="custom-contact-us-title">Contact Us</span>
        <span className="custom-contact-us-heading">HARIOM ENGINEERING WORKS</span>
        <span className="custom-contact-us-common">Plot No. C 50/2, Naresh Park Extension Industrial Area, Nangloi Delhi- 110041 (India) </span>
        <span className="custom-contact-us-common"><strong>Mobile No.</strong> : +91-9716621717 | +91- 9540214585</span>
        <span className="custom-contact-us-common"><strong>Email</strong> : hariomenggworks2011@gmail.com | stringingtools@yahoo.com | hew@tnptools.in</span>
        <span className="custom-contact-us-common"><strong>Website</strong> : www.stringingtools.in | www.tnptools.in</span>
      </div>
      <div className="custom-copyright-section"> COPYRIGHT @ 2025 HARIOM ENGINEERING WORKS - All Rights Reserved </div>
    </div>
  </>
};
