import React from 'react';

const Certifications = () => {
  return (
    <div id="clients">
      <div className="section-title">
        <h2 className='text-center'>Certifications</h2>
        <ul className='certifications-ul'>
          <li>ISO 9001:2015</li>
          <li>CE Certified</li>
        </ul>
      </div>
      <div className="social-media-container">
        <span className="social-media-text">Social Media:</span>
        <div className="social-media-icons">
          <a href="https://www.facebook.com/rajukumar.singh.3914" target="_blank"><img src="../img/icons/facebook.png" alt="" /></a>
          <a href="https://www.youtube.com/@hariomenggworksofficial" target="_blank"><img src="../img/icons/youtube.png" alt="" /></a>
          <a href="https://x.com/hariomenggwork1" target="_blank"><img src="../img/icons/twitter.png" alt="" /></a>
          <a href="https://www.linkedin.com/in/hariom-engineering-works-74412464" target="_blank"><img src="../img/icons/linkedin.png" alt="" /></a>
          <a href="https://www.instagram.com/hariomenggworks2011" target="_blank"><img src="../img/icons/instagram.png" alt="" /></a>
        </div>
      </div>
    </div>
  );
};

export default Certifications;
